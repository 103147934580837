import FirstAvatar from './1.jpg'
import SecondAvatar from './2.jpg'
import ThirdAvatar from './3.jpg'
import FourthAvatar from './4.jpg'
import FifthAvatar from './5.png'
import SixthAvatar from './6.png'
import SeventhAvatar from './7.jpg'
import EighthAvatar from './8.jpg'
import NinthAvatar from './9.jpg'
import TenthAvatar from './10.jpg'
import EleventhAvatar from './11.jpg'
import TwelfthAvatar from './12.jpg'
import ThirteenthAvatar from './13.jpg'
import FourteenthAvatar from './14.jpg'
import FifteenthAvatar from './15.jpg'
import SixteenthAvatar from './16.jpg'
import SeventeenthAvatar from './17.jpg'

export const avatars = [
  FirstAvatar,
  SecondAvatar,
  ThirdAvatar,
  FourthAvatar,
  FifthAvatar,
  SixthAvatar,
  SeventhAvatar,
  EighthAvatar,
  NinthAvatar,
  TenthAvatar,
  EleventhAvatar,
  TwelfthAvatar,
  ThirteenthAvatar,
  FourteenthAvatar,
  FifteenthAvatar,
  SixteenthAvatar,
  SeventeenthAvatar,
]
